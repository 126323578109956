/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

:root {
  --logo-color: invert(100%);
  --primary-color: #ffffff;
  --secondary-color: #c6d4f0;
  --tertiary-color: #ededed;
  --accent-blue: #0a8bec;
  --accent-green: #65b983;
  --accent-yellow: #f9c94d;
  --accent-grey: #717d92;
  --passed-color: #baf3bb;
  --failed-color: #ffe096;
  --failed-twice-color: #ffaaaa;
  --primary-background-color: #192231;
  --primary-background-color-hover: #19223180;
  --primary-background-color-vertiefungen: #192231;
  --secondary-background-color: #243350;
  --border-color: #E8ECEF;
  --modul-color: #1f2a3f;
  --modul-shadow: 7px 7px 12px 0 #0d1119;
  --modul-border-color: none;
  --shadow-color: #0d1119;
  --dropdown-color: #e8ecef;
  --slider-color-inactive: #FFFFFF;
  --slider-color-active: #FFFFFF;
  --scrollbar-color: rgba(255, 255, 255, 0.7);
  --applied-filter-font-size: 11.7px;
  --modulteppich-gap: 16px;
  --running-secondary-color: #FFFFFF;
  --toggle-background-color: #ededed;

  /* v2 */
  --fachausbildung-border-color: rgb(32, 148, 236);
  --projekt-border-color: rgb(0, 208, 205);
  --kontext-border-color: rgb(142, 79, 208);

  --grid-gap: 16px;
}

/*
Prevents elastic scroll
*/
html, body {
  overscroll-behavior-y: none;
}

body {
  background-color: var(--secondary-background-color);
  --font-family: "Open Sans";
  --font-family-numbers: "Montserrat";
  font-family: "Open Sans", "Montserrat", sans-serif;
  --font-weight-semibold: 600;
  --font-weight-regular: 100;
  margin: 0;
  min-width: fit-content;

  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--scrollbar-color);
    border: 6px solid transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@media screen and (min-width: 1301px) {
  .logo-short {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .logo-long {
    display: none;
  }
}

.test-logo {
  width: 100%;
  text-transform: uppercase;
  position: absolute;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  letter-spacing: 15px;
  font-size: 30px;
  z-index: 10;
  -webkit-transform: rotate(-5deg);
  -webkit-text-stroke: 2px red;
}

#fhnw-logo-long {
  filter: var(--logo-color);
}

#fhnw-logo-short {
  filter: var(--logo-color);
}

.passed-background-color {
  background-color: var(--passed-color) !important;
  color: black !important;
}

.failed-background-color {
  background-color: var(--failed-color) !important;
  color: black !important;
}

.failed-twice-background-color {
  background-color: #ffaaaa !important;
  color: black !important;
}

.running-background-color {
  background-color: var(--accent-blue) !important;
  color: white !important;
}

.module {
  position: relative;
  background-color: var(--modul-color);
  outline: 1px solid var(--modul-border-color);
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  box-shadow: var(--modul-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2px;
  cursor: pointer;
  overflow-wrap: break-word;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.module:hover {
  opacity: 0.6;
  transition: all 0s ease-in-out !important;
}

.rounded-module {
  height: 34px;
  width: 52px;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  color: var(--primary-color);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.rounded-module:hover {
  opacity: 1;
  cursor: default;
}

.module-height-1 {
  height: 24px;
}

.module-height-2 {
  height: 36px;
}

.module-height-3 {
  height: 48px;
}

.module-height-4 {
  height: 60px;
}

.module-height-5 {
  height: 80px;
}

.module-height-6 {
  height: 108px;
}

.module-height-7 {
  height: 120px;
}

.module-height-8 {
  height: 136px;
}

.module-height-9 {
  height: 148px;
}

.module-height-10 {
  height: 160px;
}

.module-height-11 {
  height: 180px;
}

.module-height-12 {
  height: 200px;
}

.module-width-1 {
  grid-column-end: span 1;
}

.module-width-2 {
  grid-column-end: span 2;
}

.module-width-3 {
  grid-column-end: span 3;
}

.module-width-4 {
  grid-column-end: span 4;
}

.module-width-5 {
  grid-column-end: span 5;
}

.module-width-6 {
  grid-column-end: span 6;
}

.module-width-12 {
  grid-column-end: span 12;
}

.module-width-100px {
  width: 100px;
}

.width-full {
  width: 100%;
}

.mt-15 {
  margin-top: 15px;
}

.mt-32 {
  margin-top: 32px;
}

.ects {
  text-align: center;
  cursor: default;
}

.ects div {
  height: 23px;
  letter-spacing: 0;
  line-height: 23px;
  color: var(--primary-color);
}

.ects div:first-child span:first-child {
  width: 35px;
  font-weight: bold;
}

.ects div:first-child span:last-child {
  width: 52px;
  font-weight: 300;
}

.ects div:last-child {
  height: 38px;
  color: var(--secondary-color);
  font-size: 14.04px;
  line-height: 19px;
}

.filter-breadcrumb-list {
  margin-top: 19px;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 40px;
}

.filter-breadcrumb {
  cursor: default;
  position: relative;
  border: 1px solid #0a8bec;
  color: #0a8bec;
  padding: 0 25px 0 10px;
  border-radius: 22px;
  height: 22px;
  text-transform: none;
}

.filter-breadcrumb:not(:first-child) {
  margin-left: 5px;
}

.filter-breadcrumb #x {
  cursor: pointer;
  float: right;
  position: absolute;
  right: 12px;
  height: 22px;
}

.grade img {
  float: right;
  position: absolute;
  right: 5px;
  height: 22px;
}

.grade {
  position: relative;
  display: none;
  width: 200px;
  border: 1px solid #0a8bec;
  color: #0a8bec;
  padding: 0 0 0 10px;
  border-radius: 22px;
  height: 22px;
  text-transform: none;
  margin-left: 5px;
}

.module-height-1 .grade-module {
  left: 12px !important;
}

.default-color {
  font-weight: 400;
  color: var(--primary-color);
}

.running-color {
  font-weight: 600;
  color: var(--accent-blue);
}

.running-color-white {
  font-weight: 400;
  color: var(--running-secondary-color);
}

.running-circle {
  border: 1px solid var(--accent-blue);
}

.passed {
  position: relative;
  display: none;
  width: 200px;
  border: 1px solid #0a8bec;
  color: #0a8bec;
  padding: 0 0 0 10px;
  border-radius: 22px;
  height: 22px;
  text-transform: none;
  margin-left: 5px;
}

.passed img {
  float: right;
  position: absolute;
  right: 5px;
  height: 22px;
}

.lernziele {
  width: 100%;
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

.lernziele-module {
  display: none;
}

.lernziele-module div {
  display: grid;
  height: 5px;
  width: 100%;
  grid-gap: 2px;
}

.lernziele img {
  float: right;
  position: absolute;
  right: 5px;
  height: 22px;
}

.module-group-name {
  font-size: 18.72px;
  letter-spacing: 0;
  color: var(--secondary-color);
  writing-mode: tb-rl;
  transform: rotate(180deg);
  grid-column: 1 / 2;
  position: absolute;
  height: 500px;
  bottom: 70px;
  cursor: default;
}

.studiengang-name {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  font-size: 18.72px;
  letter-spacing: 0;
  line-height: 25px;
  grid-row-start: 2;
  grid-row-end: 3;
  cursor: default;
  padding: 0 30px;
  height: 100%;
}

.studiengang-name-dark {
  background-color: var(--primary-background-color);
  color: var(--secondary-color);
  border: 1px solid var(--border-color);
}

.studiengang-name-light {
  background-color: var(--secondary-background-color);
  border: 1px solid var(--border-color);
}

.header-search {
  position: relative;
  display: flex;
  width: 220px;
  justify-content: space-between;
}

.timeline-module-padding {
  padding: 10px;
}

.timeline-leistungsuebersicht:hover {
  cursor: default;
  opacity: 1 !important;
}

.studiengang-header {
  position: relative;
  text-align: center;
}

.studiengang-header-title {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.studiengang-angerechnet-fehlgeschlagen {
  font-size: 14.04px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
}

.section {
  height: 20px;
  width: 273px;
  color: #0a8bec;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 20px;
}

.section-space {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  height: 32px;
  margin: 5px 0;
  background-color: var(--primary-background-color);
}

.disabled {
  opacity: 0.3;
  cursor: default !important;
}

.pointer {
  cursor: pointer;
}

.icon-search {
  background-image: url("assets/icons/search.svg");
}

.icon-share {
  background-image: url("assets/icons/share.svg");
}

.icon-save {
  background-image: url("assets/icons/save.svg");
}

.icon-expand-dark {
  background-image: url("assets/icons/expand-dark.svg");
}

.icon-expand-light {
  background-image: url("assets/icons/expand-light.svg");
}

.icon-warning-dark {
  background-image: url("assets/icons/modulstatus/warning-dark.svg");
}

.icon-warning-light {
  background-image: url("assets/icons/modulstatus/warning-light.svg");
}

.icon-done-dark {
  background-image: url("assets/icons/modulstatus/done-dark.svg");
}

.icon-done-light {
  background-image: url("assets/icons/modulstatus/done-light.svg");
}

.display2 {
  font-family: var(--font-family);
  font-weight: var(--font-weight-semibold);
  color: var(--primary-color);
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 1px;
}

.display4 {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  color: var(--primary-color);
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1px;
}

.display5 {
  font-family: var(--font-family);
  font-weight: var(--font-weight-semibold);
  color: var(--primary-color);
  font-size: 1.1rem;
  line-height: 1.1rem;
  letter-spacing: 1px;
}

.display6 {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: 0.9rem;
  line-height: 0.9rem;
  letter-spacing: 1px;
}

.mt-1 {
  margin: 2px;
}

.mt-8 {
  margin: 40px;
}

.mt-9 {
  margin-top: 48px;
}

.invisible {
  display: none;
}

.opacity-1 {
  opacity: 1;
}

.opacity-02 {
  opacity: 0.2;
}

.white-font {
  color: var(--primary-color);
}

.module-status-circle {
  height: 15px !important;
  width: 15px !important;
  border-radius: 50%;
}

.running-circle {
  border: 1px solid var(--accent-blue);
}

.search {
  height: 22px;
  border: none;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--primary-background-color);
  color: var(--primary-color);
}

.search:focus-visible {
  outline: none;
  border-bottom: 1px solid var(--primary-color);
  background-color: var(--primary-background-color);
  color: var(--primary-color);
}

.search-clear-button {
  position: absolute;
  bottom: 2px;
  right: 0;
  color: var(--border-color);
}

/*
Remove default white background-color when autofill input field
Source: https://stackoverflow.com/a/40397889/14667609
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: var(--primary-background-color) !important;
  color: var(--primary-color) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--primary-background-color) inset !important;
  -webkit-text-fill-color: var(--primary-color) !important;
}

/*
Remove default clear button of input field
*/
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.triangle {
  border-left: 15px solid transparent;
  border-right: 15px solid var(--accent-yellow);
  border-bottom: 15px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 2px;
  left: auto !important;
  top: 2px;
  -moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.tertiary-color {
  color: var(--tertiary-color);
}

.accent-blue {
  color: var(--accent-blue);
}

.accent-yellow {
  color: var(--accent-yellow);
}

.accent-grey {
  color: var(--accent-grey);
}

.passed-color {
  color: var(--accent-green);
}

.failed-color {
  color: var(--accent-yellow);
}

.failed-twice-color {
  color: var(--failed-twice-color);
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.primary-background-color {
  color: var(--primary-background-color);
}

.secondary-background-color {
  color: var(--secondary-background-color);
}

.currentNavigation {
  text-decoration: underline 1px solid var(--primary-color);
}

snack-bar-container.snack-bar-error {
  color: var(--primary-color);
  background-color: var(--failed-twice-color);
}

snack-bar-container.snack-bar-message {
  color: var(--primary-color);
  background-color: var(--passed-color);
}

snack-bar-container.snack-bar-error button {
  color: var(--primary-color);
}

.semester-modules {
  display: grid;
  grid-gap: 3px;
  grid-auto-flow: column;
}

.semester {
  cursor: default;
  color: var(--tertiary-color);
  font-size: 11.53px;
  letter-spacing: 0;
  line-height: 15px;
}

.modulteppich-module {
  width: 100px !important;
}

.timeline-module {
  padding: 10px;
  outline-offset: -1px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  height: 20px;
}

.overflow-leistungsuebersicht {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
  padding: 10px;
  text-align: center;
}

.angerechnet {
  text-align: center;
  cursor: default;
  position: relative;
  box-shadow: var(--modul-shadow);
  overflow-wrap: break-word;
}

.header-container-1-3 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.header-container-3-8 {
  grid-column-start: 3;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 3;
}

.profilierung-header-container {
  grid-column-start: 3;
  grid-column-end: 8;
  grid-row-start: 4;
  grid-row-end: 5;
}

.header-container-3 {
  grid-column-start: 3;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
}

.header-container-3-10 {
  grid-column-start: 3;
  grid-column-end: 10;
  grid-row-start: 2;
  grid-row-end: 3;
}

.header-container-3-6 {
  grid-column-start: 3;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
}

.not-available {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  font-size: 30px;
  color: var(--primary-color);
}

.not-available button {
  background-color: var(--primary-background-color);
  border: 1px solid var(--border-color);
  color: var(--primary-color);
  padding: 5px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.average-semster-grade {
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  line-height: 0.9rem;
  letter-spacing: 1px;
}

.outer-triangle {
  top: 18px;
  right: 38px;
  position: absolute;
  z-index: 8;
  width: 0;
  height: 0;
  border-right: 13px solid transparent;
  border-left: 13px solid transparent;
  border-bottom: 13px solid var(--border-color);
}

.inner-triangle {
  position: absolute;
  top: 2px;
  left: -12px;
  width: 0;
  height: 0;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid var(--primary-background-color);
}

.dropdown-padding {
  padding: 4px 8px 4px 16px;
}

.dropdown-container {
  position: absolute;
  right: 0;
  top: 30px;
  background-color: var(--primary-background-color);
  color: var(--primary-color);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 10px 0;
  z-index: 7;
}

.expand-dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}

.collapse-dropdown {
  visibility: hidden;
  opacity: 0;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}

.switch {
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 18px;
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
  right: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-background-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  left: 4px;
  bottom: 4px;
  background-color: var(--slider-color-inactive);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--accent-blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--accent-blue);
}

input:checked + .slider:before {
  background-color: var(--slider-color-active);
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}

.triangle {
  border-left: 15px solid transparent;
  border-right: 15px solid var(--accent-yellow);
  border-bottom: 15px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 2px;
  left: auto !important;
  top: 2px;
  -moz-transition: all 0.4s ease-in-out !important;
  -o-transition: all 0.4s ease-in-out !important;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

.display-none {
  display: none;
}

.height-full {
  height: 100%;
}

.ects-indicator {
  z-index: 1;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-45 {
  margin-top: 45px;
}
